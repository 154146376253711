// @flow
import React, {useEffect, useState} from 'react';
import type {Node} from 'react';
import {Theme} from '@wellstone-solutions/common';
import type {
  FormType,
  UIExternalRelationshipType,
} from '@wellstone-solutions/common';
import {Box, Button, Stack, Typography} from '@wellstone-solutions/web';
import {EHRWrapper} from 'modules/integration/components';

type PropsType = {
  form: FormType<any>,
  memberType: string,
  step: any,
  onNext: () => void,
};

export const IntegrationStep = ({
  form,
  memberType = 'member',
  step,
  onNext,
}: PropsType): Node => {
  // ehrData is the response data from EHR provider
  const [ehrData, setEHRData] = useState<UIExternalRelationshipType>({});
  const showNonEHROption =
    Object.keys(ehrData).length === 0 || !form.values.externalRelationshipId;

  const orgLabel = step.data.orgIntegration?.name;

  useEffect(() => {
    // Reset the form on mount
    // This is to ensure things are cleared out if the user
    // navigates back to the beginning
    // $FlowFixMe
    form.resetForm();
    setEHRData({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Ensures we always clear EHR data if externalRelationshipId is empty
    if (
      !form.values.externalRelationshipId ||
      form.values.externalRelationshipId?.length === 0
    ) {
      setEHRData({});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.externalRelationshipId]);

  const onNextWrapper = () => {
    // If no email, then force flow down email entry path
    if (!form.values.email) {
      step?.data.setRequiresEmailEntry(true);
    } else {
      onNext();
    }
  };

  return (
    <Stack direction="column">
      <EHRWrapper
        form={form}
        memberType="member"
        orgIntegration={step.data.orgIntegration}
        setIntegration={setEHRData}
        step={step}
      />

      {/* Hide the option to use email if external integration found */}
      {showNonEHROption && (
        <Box sx={{mt: 4, textAlign: 'center'}}>
          <Typography
            sx={{fontWeight: 'bold', color: Theme.colorPalette.darkest}}>
            {`Don't have the ${orgLabel} ID handy?`}
          </Typography>
          <Typography sx={{color: Theme.colorPalette.darker}}>
            You can create a profile using the member's email.
          </Typography>
          <Button
            sx={{textTransform: 'none'}}
            onClick={() => {
              step.data.setRequiresEmailEntry(true);
            }}>
            Use member's email
          </Button>
        </Box>
      )}

      {/* Footer and onNext handled manually due to complexity in this step*/}
      {!showNonEHROption && (
        <Box sx={{mt: 4}}>
          <Button
            variant="contained"
            sx={styles.nextButton}
            onClick={onNextWrapper}>
            Next
          </Button>
        </Box>
      )}
    </Stack>
  );
};

const styles = {
  nextButton: {
    borderRadius: 10,
    fontWeight: 'bold',
    px: 4,
    textTransform: 'capitalize',
    marginBottom: 1,
  },
};
