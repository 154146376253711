// @flow
import React, {useEffect} from 'react';
import {observer} from 'mobx-react';
import type {ComponentType, Node} from 'react';
import type {UIResourceType} from '@wellstone-solutions/common/models/rest';
import {Box} from '@wellstone-solutions/web';
import {
  Events,
  BridgeEventSources as EventSources,
} from '@wellstone-solutions/common';
import {ResourceGrid} from '../ResourceGrid';
import {useResourcesContext} from '../../contexts';
import {useStores} from 'hooks/useStores';
import {useEventSource} from 'hooks/useEventSource';

export const SavedResources: ComponentType<{}> = observer((): Node => {
  useEventSource(EventSources.SAVED_RESOURCES_PAGE);
  const {resourceStoreV2, eventStore} = useStores();
  const {savedSearchTerm, savedCollectionFilter} = useResourcesContext();

  useEffect(() => {
    eventStore.addEvent(Events.USER_VIEWED_SAVED_RESOURCES);
  }, [eventStore]);

  const getFilteredResources = (): Array<UIResourceType> => {
    const lowerCaseSearchTerm = savedSearchTerm.toLowerCase();
    const searchFields = ['title', 'subtitle', 'description'];

    const result = resourceStoreV2.userResources
      .map((ur) => ur.resource)
      .filter((resource) => {
        return (
          searchFields.some((field) => {
            return (
              resource[field] &&
              resource[field].toLowerCase().includes(lowerCaseSearchTerm)
            );
          }) &&
          (savedCollectionFilter.length === 0 ||
            savedCollectionFilter.some((collection) =>
              resource.collections.some((col) => col.id === collection),
            ))
        );
      });

    if (savedSearchTerm || savedCollectionFilter.length > 0) {
      eventStore.addEvent(Events.USER_FILTERED_RESOURCES, {
        query: savedSearchTerm,
        collections: savedCollectionFilter,
        result_count: result.length,
      });
    }
    return result;
  };

  const filteredResources = getFilteredResources();

  return (
    <Box>
      <ResourceGrid
        isLoading={resourceStoreV2.isLoadingUserResources}
        resources={filteredResources}
        totalCount={filteredResources.length}
      />
    </Box>
  );
});
