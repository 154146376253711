// @flow
import React from 'react';
import {observer} from 'mobx-react';
import type {ComponentType, Node} from 'react';
import {Link as RouterLink} from 'react-router-dom';

// FROM MUI
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';

import {Theme} from '@wellstone-solutions/common';
import PfBridgeLogo from '../../../../assets/images/PathfinderBridgeLight.svg';

// FF
import {
  hasFlag,
  HIDE_SEARCH_SITE,
  MAINTENANCE_MODE_BRIDGE,
} from 'constants/FeatureFlags';
import {useStores} from 'hooks/useStores';

// AppBar in common cannot hold a ref so I am using from MUI
import AppBar from '@mui/material/AppBar';
import {Toolbar, Box, Link} from '@wellstone-solutions/web';

import {ProfilePanel} from './ProfilePanel';
import {NotificationsPanel} from './NotificationsPanel';
import {SearchPanel} from './SearchPanel.js';
import {NavigationPanel} from './NavigationPanel.js';

import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface ScrollProps {
  children: Node;
}
function HideOnScroll(props: ScrollProps) {
  const {children} = props;
  const theme = useTheme();
  const trigger = useScrollTrigger();

  return (
    <Slide
      appear={false}
      easing={theme.transitions.easing.sharp}
      direction="down"
      className={trigger ? 'hidden' : 'visible'}
      in={!trigger}>
      {children}
    </Slide>
  );
}

type PropsType = {};
export const SiteHeader: ComponentType<PropsType> = observer((props) => {
  const theme = useTheme();
  const {appStore, meStore} = useStores();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isAuthorized =
    meStore.isAuthenticated() &&
    !appStore.apiMaintenanceMode &&
    !hasFlag(MAINTENANCE_MODE_BRIDGE, meStore.features) &&
    !hasFlag(HIDE_SEARCH_SITE, meStore.features);

  return (
    <>
      <HideOnScroll {...props}>
        <AppBar
          data-testid="responsive-header"
          style={styles.header}
          id="site_header">
          {isAuthorized ? (
            <Toolbar
              data-testid="header_toolbar"
              sx={isTablet ? styles.toolbarSmall : styles.toolbar}>
              {isTablet && (
                <Box className="header_tool" sx={styles.tool}>
                  <NavigationPanel />
                </Box>
              )}
              <Link component={RouterLink} to="/dashboard">
                <img
                  alt="Pathfinder.Bridge"
                  src={PfBridgeLogo}
                  data-testid="PFLogo"
                  style={isTablet ? styles.bridgeLogoSmall : styles.bridgeLogo}
                />
              </Link>
              <Box
                sx={styles.headerTools}
                style={
                  isMobile
                    ? {
                        gridTemplateColumns: '1fr 50px 50px',
                      }
                    : {}
                }>
                <Box
                  sx={{
                    ...styles.tool,
                    justifyContent: 'end',
                  }}>
                  <SearchPanel />
                </Box>
                <Box sx={styles.tool} className="header_tool">
                  <NotificationsPanel />
                </Box>
                <Box sx={styles.tool} className="header_tool">
                  <ProfilePanel />
                </Box>
              </Box>
            </Toolbar>
          ) : (
            <Box data-testid="logo-header" sx={styles.unauthorizedHeader}>
              <img
                alt="Pathfinder.Bridge"
                src={PfBridgeLogo}
                data-testid="PFLogo"
                style={isTablet ? styles.bridgeLogoSmall : styles.bridgeLogo}
              />
            </Box>
          )}
        </AppBar>
      </HideOnScroll>
    </>
  );
});

const styles = {
  header: {
    backgroundColor: Theme.colorPalette.primary,
    color: Theme.colorPalette.onPrimary,
    height: '80px',
    zIndex: 1100,
  },
  navigationTool: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  toolbar: {
    display: 'grid',
    gridTemplateColumns: '200px 1fr',
    width: '100%',
  },
  toolbarSmall: {
    display: 'grid',
    gridTemplateColumns: '40px 140px 1fr',
    width: '100%',
    pl: '8px',
    pr: '8px',
  },
  headerTools: {
    display: 'grid',
    gridTemplateColumns: '1fr 65px 65px',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80px',
  },
  tool: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bridgeLogo: {
    width: '170px',
    marginLeft: '0px',
  },
  bridgeLogoSmall: {
    width: '115px',
    marginLeft: '10px',
  },
  unauthorizedHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    height: '80px',
    paddingLeft: '40px',
  },
};
