// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import moment from 'moment';
import {Hooks, Models, Validation} from '@wellstone-solutions/common';
import {ROLES} from 'utils/Permissions';
import {DEFAULT_DATE_FORMAT} from 'constants/dates';
import {ALERT_TYPES, showAlert} from 'utils/showAlert';
import {useStores} from 'hooks/useStores';
import {Wizard} from 'components/Wizard';
import type {WizardStepType} from 'components/Wizard';
import {
  EmailStep,
  IntegrationStep,
  MemberInfoStep,
  ProgramsStep,
  GroupsStep,
  ConfirmationStep,
} from './steps';
import {DEFAULT_TITLE} from './constants';

const isValidDateRange = Validation.dateIsAfter('admissionStart', true);

type PropsType = {
  onCancel: () => void,
};

const wizardSchema = {
  ...Models.Member.schema,
  gender: Validation.isRequired,
  identifiesAs: Validation.isRequired,
  birthdate: {
    validate: Validation.isEligibleAge,
    error: 'Members must be 13 and over',
  },
  admissionStart: Validation.isRequired,
  admissionEnd: {
    validate: isValidDateRange,
    error: 'End Date must be after Start Date',
  },
  programs: (value) => value.find((program) => !program.existing),
  groups: (value) => value.find((group) => !group.existing),
};

export const CreateWizard = ({onCancel}: PropsType): Node => {
  const {memberStore, meStore} = useStores();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const orgIntegration = meStore.ehrIntegrationOption;
  const [requiresEmailEntry, setRequiresEmailEntry] = useState(!orgIntegration);

  const integrationLabel = orgIntegration?.name;

  const steps: Array<WizardStepType> = [
    requiresEmailEntry
      ? {
          name: 'EmailStep',
          subtitle: (form) =>
            `Enter the member's email address so we can verify it in Bridge.<br />${
              form.values.name.length === 0 // only show tip if name is empty as it might've been set by EHR
                ? "**Quick Tip!** You will also need the person's full name and birthdate."
                : ''
            }`,
          component: EmailStep,
          disableFooter: !!orgIntegration,
          data: {
            setRequiresEmailEntry,
            orgIntegration: orgIntegration,
          },
          isComplete: (formValues: any) => wizardSchema.email(formValues.email),
        }
      : {
          name: 'IntegrationStep',
          subtitle: () =>
            `You can create a profile in Bridge by using a Member's ${integrationLabel} ID or their email address.`,
          component: IntegrationStep,
          disableFooter: true,
          data: {
            setRequiresEmailEntry,
            orgIntegration: orgIntegration,
          },
          isComplete: (formValues: any) =>
            formValues.externalRelationshipId?.length > 0,
        },
    {
      name: 'MemberInfoStep',
      subtitle: () => 'All fields are required.',
      component: MemberInfoStep,
      data: {
        orgIntegration: orgIntegration,
      },
      // Not checking birthdate, existing users may not have one set
      isComplete: (formValues: any) =>
        wizardSchema.email(formValues.email) &&
        wizardSchema.name(formValues.name) &&
        wizardSchema.identifiesAs(formValues.identifiesAs) &&
        wizardSchema.gender(formValues.gender) &&
        wizardSchema.role(formValues.role) &&
        // Cannot add a patient
        formValues.role === ROLES.patient &&
        // Only require birthdate if new
        (formValues.id ||
          (!formValues.id && Validation.isEligibleAge(formValues.birthdate))),
      title: (form) =>
        form.values?.id ? 'Update existing member' : DEFAULT_TITLE,
    },
    {
      name: 'ProgramsStep',
      subtitle: (form) =>
        `**Next, assign ${form.values.name} to one or more Programs.** Programs are specific clinics, locations, or departments that focus on a specific area of recovery.`,
      component: ProgramsStep,
      isComplete: (formValues: any) =>
        wizardSchema.programs(formValues.programs) &&
        formValues.programs.some(({existing}) => !existing) &&
        formValues.admissionStart &&
        isValidDateRange(formValues.admissionEnd, formValues),
      title: (form) =>
        form.values?.id ? 'Update existing member' : DEFAULT_TITLE,
    },
    {
      name: 'GroupsStep',
      subtitle: (form) =>
        `**Now, assign ${form.values.name} to one or more Groups.** A group is a small set of members associated with a specific staff member or specific section of services.`,
      component: GroupsStep,
      isComplete: (formValues: any) => wizardSchema.groups(formValues.groups),
      title: (form) =>
        form.values?.id ? 'Update existing member' : DEFAULT_TITLE,
    },
    {
      name: 'ConfirmationStep',
      subtitle: (form) =>
        `**You’re almost done!** Review ${form.values.name}'s details, then hit Save. If you need to make edits in the future, you can do that on ${form.values.name}’s profile page.`,
      component: ConfirmationStep,
      data: {
        orgIntegration: orgIntegration,
      },
      isComplete: (formValues: any) => true,
      submitText: (form) => 'Save',
      title: (form) =>
        form.values?.id ? 'Update existing member' : DEFAULT_TITLE,
    },
  ];

  const form = Hooks.useForm({
    initialValues: {
      ...Models.Member.Factory(),
      programs: [],
      admissionStart: '',
      admissionEnd: '',
    },
    schema: wizardSchema,
    // Not used here
    onSubmit: () => undefined,
  });

  const onSubmit = async () => {
    setIsSubmitting(true);

    let {programs, groups, id, admissionStart, admissionEnd} = form.values;

    let shouldCreateAdmissions = true;

    // Adjust messaging to when we aren't adding a new member
    // call out that we simply added the to programs and groups
    const createMessage = !id
      ? 'Created a new member.'
      : 'Added member to program(s) and group(s).';

    let memberId;
    // Create member if not already created
    if (!id) {
      const uiInput = Models.Member.toUIInput({
        ...form.values,
        user: {email: form.values.email},
      });

      const result = await memberStore.createMember(
        Models.Member.toApiInput(uiInput),
      );

      shouldCreateAdmissions = result.isSuccess;
      // Capture new member id if created ok
      if (result.isSuccess) {
        memberId = result.data.id;
        id = memberId;
      } else {
        showAlert('Failed to create new member.', ALERT_TYPES.error);
        onCancel();
        setIsSubmitting(false);
        return;
      }
    }

    if (shouldCreateAdmissions) {
      // Let's add program and group admissions now
      const newPrograms = programs
        .filter(({existing}) => !existing)
        .map((program) => program);
      const newGroups = groups
        .filter(({existing}) => !existing)
        .map((group) => group);

      const startDate = moment(admissionStart).format(DEFAULT_DATE_FORMAT);
      const endDate = moment(admissionEnd).format(DEFAULT_DATE_FORMAT);

      const admissionResults = await Promise.all([
        ...newPrograms.map(
          (program) =>
            memberStore.createProgramAdmission({
              programId: program.id,
              admissionInput: {
                memberId: id,
                startDate,
                endDate,
              },
            }),
          ...newGroups.map((group) =>
            memberStore.createGroupAdmission({
              groupId: group.id,
              admissionInput: {
                memberId: id,
                startDate,
                endDate,
              },
            }),
          ),
        ),
      ]);

      const admissionSuccess = admissionResults.some(
        (admissionResult) => admissionResult.isSuccess,
      );

      if (admissionSuccess) {
        showAlert(createMessage);
      } else {
        showAlert(
          'Failed to add new member to requested programs and/or groups.',
          ALERT_TYPES.ERROR,
        );

        onCancel();
      }
    }

    // Only create EHR integration if we have both external ID and created admission successfully (ie. have a memberId)
    const shouldCreateEHRIntegration =
      memberId && form.values.externalRelationshipId?.length > 0;

    if (shouldCreateEHRIntegration) {
      const response = await memberStore.createEHRIntegration({
        // $FlowIgnore - memberId check already avoids undefined case
        memberId,
        externalRelationshipId: form.values.externalRelationshipId,
      });

      if (!response.isSuccess) {
        showAlert(
          `Failed to add ${integrationLabel} integration.`,
          ALERT_TYPES.error,
        );
      }
    }

    setIsSubmitting(false);
  };

  return (
    <Wizard
      isSubmitting={isSubmitting}
      form={form}
      steps={steps}
      title={DEFAULT_TITLE}
      onCancel={onCancel}
      onWizardSubmit={onSubmit}
    />
  );
};
