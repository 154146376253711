// React
import React, {useEffect, useRef, useState} from 'react';

//Third party
import {observer} from 'mobx-react';
import 'emoji-mart/css/emoji-mart.css';
import {Picker, Emoji} from 'emoji-mart';

// Bulma
import {Field, Control} from 'react-bulma-components/lib/components/form';

// WS
import {Events, Roles, Theme} from '@wellstone-solutions/common';
import {Box, Typography, Icon, IconNames} from '@wellstone-solutions/web';
import {PfButton} from 'components/shared/PfButton';
import PfModal from '../modal/PfModal';
import {ResourceAccordion} from 'modules/resources';
import {Actions, Types} from '../../constants/Messages';
import {useStores} from 'hooks/useStores';
import {EventEmitter} from '../../utils/EventEmitter';
import {Palette} from '../../palette';
import {QuestionModel} from 'modules/resources';
import {hasFlag, RESOURCES_V2} from 'constants/FeatureFlags';

const keyCodes = {
  ENTER: 13,
};

const ChatModuleInputs = (props) => {
  const {meStore, pubnubStore, eventStore} = useStores();
  const {channel, chatMember} = props;
  const [nextMessage, setNextMessage] = useState('');
  const [resourceModalOpen, setResourceModalOpen] = useState(false);
  const [typingStatus, setTypingStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const typingTimeout = useRef();
  const chatInput = useRef();

  // componentDidMount
  useEffect(() => {
    return () => {
      setTyping(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setTyping = (isTyping, delayedReset) => {
    clearTimeout(typingTimeout.current);
    if (delayedReset) {
      typingTimeout.current = setTimeout(() => {
        setTyping(false);
      }, 5000);
    }
    if (channel && typingStatus !== isTyping) {
      pubnubStore.setState({
        channels: [channel?.name],
        uuid: meStore.me.id,
        state: {meTyping: isTyping},
      });
      setTypingStatus(isTyping);
    }
  };

  const shareResource = async (resource, action = Actions.SHARED_RESOURCE) => {
    const content =
      action === Actions.SHARED_RESOURCE
        ? QuestionModel.toShare(resource)
        : resource;

    const message = {
      channel: channel?.name,
      data: {
        type: 'message',
        action: action,
        content: JSON.stringify(content),
        name: meStore.me.name,
        author: meStore.me.id,
      },
    };

    await pubnubStore.publish(channel?.name, message);
    eventStore.addEvent(Events.USER_SHARED_RESOURCE_FROM_CHAT, {
      action,
      id: resource.id,
    });
    setNextMessage('');
    setResourceModalOpen(false);
    EventEmitter.dispatch('closeModal');
  };

  const publishMessage = async (message = '') => {
    if (isLoading || message.length === 0) {
      return false;
    }
    setIsLoading(true);

    const msg = {
      channel: channel?.name,
      data: {
        type: Types.MESSAGE,
        action: Actions.CREATED,
        content: message,
        name: meStore.me.name,
        author: meStore.me.id,
      },
    };

    const eventData = {
      sent_to_staff: Boolean(chatMember && chatMember.role !== Roles.PATIENT),
      sent_by_staff: meStore.isStaffMember,
    };

    await pubnubStore.publish(channel?.name, msg);
    eventStore.addEvent(Events.USER_CREATED_MESSAGE, eventData);
    chatInput.current.parentNode.dataset.replicatedValue = '';
    setNextMessage('');
    setTyping(false);
    setIsLoading(false);
  };

  const addEmoji = (emoji) => {
    let msg = nextMessage + emoji.native;
    EventEmitter.dispatch('closeModal');
    if (props.onCloseModal) {
      props.onCloseModal();
    }
    setNextMessage(msg);
  };

  const onInputChange = (newVal) => {
    chatInput.current.parentNode.dataset.replicatedValue = newVal.target.value;
    setTyping(true, true);
    setNextMessage(newVal.target.value);
  };

  const inputBlur = (content) => {
    setTyping(false);
  };

  return (
    <div>
      <div style={styles.inputRow}>
        <div style={styles.inputField} className="grow-wrap">
          <textarea
            ref={chatInput}
            name="text"
            id="text"
            value={nextMessage}
            onChange={onInputChange}
            onKeyUp={(e) => {
              if (e.keyCode === keyCodes.ENTER) {
                publishMessage(e.target.value);
              }
            }}
            onBlur={() => inputBlur()}
            placeholder="message here"
          />
        </div>
        <PfButton noStyles onClick={() => publishMessage(nextMessage)}>
          <Icon
            style={nextMessage.length ? styles.enabled : styles.disabled}
            name={IconNames.ArrowRightCircle}
            color={Palette.PRIMARY}
          />
        </PfButton>
      </div>
      <Field>
        <Control style={styles.control}>
          {/* Remove RESOURCES_V2 feature flag, see PAT-3489 */}
          {!hasFlag(RESOURCES_V2, meStore.features) && (
            <PfModal
              isModalOpen={resourceModalOpen}
              icon={{
                name: IconNames.ShareIos,
                color: Theme.colorPalette.lightest,
              }}
              button={{outlined: false, text: 'Share Resource'}}
              modal={{closeOnBlur: true, showClose: false, closeOnEsc: true}}
              onOpen={() => setResourceModalOpen(true)}
              disabled={document.body.classList.contains('is-tutorial')}
              contentStyle={styles.resourceModal}>
              <Box sx={styles.wrapper}>
                <Typography variant="h5" sx={styles.title}>
                  Resources
                </Typography>
                <ResourceAccordion shareResource={shareResource} />
              </Box>
            </PfModal>
          )}
          <PfModal
            button={{
              outlined: false,
              text: <Emoji emoji=":grinning:" set="apple" size={24} />,
            }}
            modal={{
              closeOnBlur: true,
              showClose: false,
              closeOnEsc: true,
            }}
            buttonContainerStyle={styles.mL}
            buttonStyle={styles.emojiButton}>
            <Picker
              onSelect={addEmoji}
              darkMode={false}
              title="Pick an emoji…"
              emoji="point_up"
              perLine={12}
            />
          </PfModal>
        </Control>
      </Field>
    </div>
  );
};

const styles = {
  wrapper: {
    py: 3,
    px: 3,
    height: '100%',
    position: 'relative',
  },
  resourceModal: {
    padding: 0,
    width: '100%',
    height: '100%',
  },
  mL: {
    marginLeft: 10,
  },
  emojiButton: {
    width: '2rem',
  },
  pickerWrapper: {
    zIndex: 999,
    position: 'absolute',
  },
  picker: {
    position: 'absolute',
    width: 350,
    top: '-0.75rem',
    left: '-0.75rem',
  },
  control: {
    display: 'flex',
  },
  inputRow: {
    display: 'flex',
    marginBottom: 10,
    alignItems: 'center',
  },
  inputField: {
    marginRight: 10,
  },
  enabled: {
    cursor: 'pointer',
  },
  disabled: {
    opacity: 0.5,
  },
};

export default observer(ChatModuleInputs);
