// @flow
export const RESOURCE_MODAL_VIEWS = {
  detail: 'detail',
  share: 'share',
};

export const DEFAULT_FILTERS: {
  query?: string,
  collections?: Array<string>,
  distance?: number | null,
} = {
  query: '',
  collections: [],
  distance: null,
};

export const CONTACT_METHODS = {
  phone: 'phone',
  email: 'email',
  website: 'website',
  address: 'address',
};
