// @flow
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import type {ComponentType, Node} from 'react';

import {
  Button,
  Icon,
  IconNames,
  Chip,
  Grid,
  Box,
  Typography,
  AutocompleteSearch,
} from '@wellstone-solutions/web';

import {useTheme} from '@mui/material/styles';
import {Theme} from '@wellstone-solutions/common';

import {useStores} from 'hooks/useStores';
import useMediaQuery from '@mui/material/useMediaQuery';

import {SiteHeaderDrawer} from './SiteHeaderDrawer';

// Is there a type in common for this
type MemberResultType = {
  label: Node,
  option: {
    id: string,
    name: string,
    role: string,
  },
};

export const MemberOption = (member: MemberResultType) => {
  const chipColor = Theme.colorPalette.secondary3;
  const chipTextColor = Theme.colorPalette.onSecondary;
  const {label, option} = member;

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={style.searchOption}>
      <Box data-testid="member_element">
        <Typography variant="body1">{label}</Typography>
      </Box>
      <Box>
        <Chip
          label={option.role}
          variant="default"
          sx={{
            backgroundColor: chipColor,
            color: chipTextColor,
            height: '1.8em',
          }}
          data-testid="member_chip"
        />
      </Box>
    </Grid>
  );
};

function MobilePopper(props) {
  const {open} = props;
  return (
    <Box
      {...props}
      sx={{
        display: open ? 'block' : 'none',
        position: 'static',
        marginTop: '2px',
        zIndex: 'auto',
        height: 'auto',
        '& .MuiAutocomplete-option': {
          borderBottom: '1px rgba(0, 0, 0, 0.12) solid',
          '&:last-child': {
            borderBottom: 'none',
          },
        },
        '& .MuiAutocomplete-listbox': {
          height: 'auto',
          maxHeight: 'calc(100svh - 225px)',
        },
      }}
    />
  );
}

type SearchPanelPropsType = {};
export const SearchPanel: ComponentType<SearchPanelPropsType> = (props) => {
  const [searchAnchorEl, setSearchAnchorEl] = useState(null);
  const {organizationStore} = useStores();
  const navigate = useNavigate();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    setSearchAnchorEl(null);
  };
  const handleClick = (event) => {
    let anchor = event.target;
    setSearchAnchorEl(anchor);
  };
  const onSearch = async (searchTerm) => {
    const response = await organizationStore.search({
      searchTerm,
      queryBy: ['members'],
      statusFilters: ['active'],
    });

    if (response.isSuccess && response.data.members) {
      return response.data.members.map(({id, name, role}) => ({
        id,
        name,
        role,
      }));
    } else {
      return [];
    }
  };

  // TODO: Is there a proper type in commmon for this?
  type MemberOptionType = {
    id: string,
    name: string,
    role: string,
  };

  const onMemberSelect = (result: MemberOptionType) => {
    let role = result.role === 'patient' ? 'members' : 'staff';
    let target = `/${role}/${result.id}`;
    handleClose();
    navigate(target);
  };

  const INPUT_PLACEHOLDER = 'Seach for members by name or email';

  return isTablet ? (
    <>
      <Button
        data-testid="header-search-button"
        onClick={handleClick}
        tabIndex={-1}
        sx={{
          padding: '8px 0',
          minWidth: '40px',
          marginRight: '0px',
        }}>
        <Icon
          name={IconNames.Search}
          size={isMobile ? 22 : 24}
          color={theme.palette.primary.contrastText}
        />
      </Button>
      <SiteHeaderDrawer
        onClose={handleClose}
        title="Search"
        anchorEl={searchAnchorEl}
        transitionDirection="left"
        transitionTimeout={0}>
        <Box sx={{padding: '10px 20px'}}>
          <AutocompleteSearch
            testId="header-search-form-drawer"
            optionLabelKey="name"
            optionValueKey="id"
            renderOption={MemberOption}
            onOptionSelect={onMemberSelect}
            onSearch={onSearch}
            label={INPUT_PLACEHOLDER}
            value={null}
            PopperComponent={MobilePopper}
          />
        </Box>
      </SiteHeaderDrawer>
    </>
  ) : (
    <AutocompleteSearch
      testId="header-search-form"
      label={null}
      optionLabelKey="name"
      optionValueKey="id"
      placeholder={INPUT_PLACEHOLDER}
      renderOption={MemberOption}
      onOptionSelect={onMemberSelect}
      onSearch={onSearch}
      value={null}
      sx={style.headerForm}
    />
  );
};

const style = {
  searchOption: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%',
    letterSpacing: '0.15px',
    padding: '6px 0px',
  },
  headerForm: {
    width: 'clamp(300px, 75%, 500px)',
    backgroundColor: '#fff',
    marginRight: '5px',
    borderRadius: '4px',
    ' .MuiFormControl-root .MuiInputBase-root': {
      paddingTop: '0px',
      paddingBottom: '0px',
      borderWidth: 0,
      borderRadius: '4px',
    },
  },
  chip: {
    fontWeight: 800,
    textTransform: 'capitalize',
    backgroundColor: Theme.colorPalette.secondary3,
    color: Theme.colorPalette.onSecondary,
  },
};
