// @flow

import React from 'react';
import type {RouteType} from 'types';
import {Link} from 'react-router-dom';
import {Theme} from '@wellstone-solutions/common';
import {ListItemButton} from '@mui/material';

import {
  Icon,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@wellstone-solutions/web';

type NavProps = {
  route: RouteType,
  selected: Boolean,
  onNavigate: (event: SyntheticEvent<HTMLButtonElement>) => void,
};
export const NavigationElement: ComponentType<NavProps> = ({
  route,
  selected,
  onNavigate,
  open = true,
}) => {
  return (
    <ListItem
      data-testid="primary-navigation-link"
      component={Link}
      to={route.path}
      key={route.name}
      disablePadding
      className={open ? 'open' : null}
      sx={
        selected
          ? {
              ...style.li,
              backgroundColor: Theme.colorPalette.secondary4,
              color: Theme.colorPalette.onSecondary,
            }
          : style.li
      }
      onClick={() => {
        onNavigate(route);
      }}>
      <Tooltip title={open ? null : route.name} placement="right" arrow>
        <ListItemButton
          data-testid="primary-navigation-button"
          sx={{
            height: '48px',
            justifyContent: open ? 'initial' : 'center',
          }}>
          <ListItemIcon
            sx={{
              minWidth: 0,
              justifyContent: 'center',
              mr: open ? 3 : 'auto',
            }}>
            <Icon
              name={route.icon}
              size={24}
              color={
                selected
                  ? Theme.colorPalette.secondary3
                  : Theme.colorPalette.primary
              }
              sx={
                selected
                  ? {...style.icon, color: Theme.colorPalette.secondary3}
                  : style.icon
              }
            />
          </ListItemIcon>
          <ListItemText
            primary={route.name}
            sx={
              selected
                ? {
                    ...style.label,
                    color: Theme.colorPalette.darker,
                  }
                : style.label
            }
          />
        </ListItemButton>
      </Tooltip>
    </ListItem>
  );
};

const style = {
  li: {
    display: 'block',
    borderRadius: '4px',
    maxWidth: '48px',
    '&.open': {
      maxWidth: '247px',
      marginLeft: '0',
      ' .MuiListItemText-root': {
        opacity: 1,
        display: 'inline-block',
      },
    },
    '& .MuiListItemText-root': {
      opacity: 0,
      display: 'none',
    },
    // hover states
    '&:hover': {
      backgroundColor: Theme.colorPalette.secondary3,
      '& .MuiListItemText-root': {
        color: Theme.colorPalette.onSecondary,
        diaplay: 'inline-block',
      },
      '& .MuiListItemIcon-root svg': {
        color: Theme.colorPalette.onSecondary,
      },
    },
  },
  label: {
    color: Theme.colorPalette.darker,
    whiteSpace: 'nowrap',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
