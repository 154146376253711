// @flow
import {action, computed, decorate, runInAction, observable} from 'mobx';
import type {
  ApiResponseType,
  UILocationType,
  UILocationSuggestionType,
  UIUserLocationType,
} from '@wellstone-solutions/common';
import {Models} from '@wellstone-solutions/common';
import RootStore from 'mobx/RootStore';

const {Location} = Models;

export class LocationStore {
  rootStore: RootStore;
  currentLocation: UIUserLocationType | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  init() {
    const {meStore} = this.rootStore.stores;
    if (meStore.activeMembership.current_location) {
      this.currentLocation = Location.toUIUserLocation(
        meStore.activeMembership.current_location,
      );
    }
  }

  async getLocationSuggestions(
    query: string,
  ): Promise<ApiResponseType<Array<UILocationSuggestionType>>> {
    const response = await Location.getLocationSuggestions({query});
    if (response.isSuccess) {
      const uiSuggestions = response.data.map(Location.toUILocationSuggestion);
      return {...response, data: uiSuggestions};
    } else {
      return {...response, data: []};
    }
  }

  async saveCurrentLocation(
    location: UILocationType,
  ): Promise<ApiResponseType<UIUserLocationType | null>> {
    const response = await Location.saveUserLocation(
      Location.toApiLocation(location),
    );

    const newUserLocation = response.isSuccess
      ? Location.toUIUserLocation(response.data)
      : null;

    runInAction(() => {
      this.currentLocation = newUserLocation;
    });

    return {...response, data: newUserLocation};
  }

  async getCurrentLocation(
    forceFetch: boolean = false,
  ): Promise<ApiResponseType<UIUserLocationType | null>> {
    if (!forceFetch && this.currentLocation) {
      return Promise.resolve({
        isSuccess: true,
        status: 200,
        errors: [],
        data: this.currentLocation,
      });
    }

    const response = await Location.getUserLocation();
    if (response.isSuccess && response.data.length > 0) {
      const newUserLocation = Location.toUIUserLocation(response.data[0]);
      runInAction(() => {
        this.currentLocation = newUserLocation;
      });

      return {...response, data: newUserLocation};
    } else {
      runInAction(() => {
        this.currentLocation = null;
      });
      return {...response, data: null};
    }
  }

  get currentLocationDisplayText(): string | null {
    if (!this.currentLocation) return null;

    const {city, state, formattedAddress} = this.currentLocation.location;

    if (city && state) {
      return `${city}, ${state}`;
    }

    return formattedAddress || null;
  }
}

decorate(LocationStore, {
  currentLocation: observable,
  getLocationSuggestions: action,
  saveCurrentLocation: action,
  getCurrentLocation: action,
  currentLocationDisplayText: computed,
});
