// @flow
import React, {useState} from 'react';
import type {ComponentType, Node} from 'react';
import {Theme} from '@wellstone-solutions/common';
import {
  Box,
  Button,
  Icon,
  IconButton,
  IconNames,
  Stack,
  Typography,
} from '@wellstone-solutions/web';
import {observer} from 'mobx-react';
import {useStores} from 'hooks/useStores';
import {ResponsiveDialog} from './ResponsiveDialog';
import {PageTitle} from '../ResourcesHeader';
import {LocationSearch} from 'modules/location';

export const CurrentLocation: ComponentType<{}> = observer((): Node => {
  const {locationStore} = useStores();
  const [dialogAnchorEl, setDialogAnchorEl] = useState(null);
  const [value, setValue] = useState(
    locationStore.currentLocation && locationStore.currentLocation.location,
  );

  const handleClose = () => {
    if (value) {
      locationStore.saveCurrentLocation(value);
    }
    setDialogAnchorEl(null);
  };

  return (
    <>
      <Button
        sx={styles.button}
        variant="text"
        onClick={(event) => setDialogAnchorEl(event.currentTarget.parentNode)}
        startIcon={
          <Icon name={IconNames.MapPin} color={Theme.colorPalette.primary} />
        }>
        {locationStore.currentLocationDisplayText || 'Set Location'}
      </Button>

      <ResponsiveDialog
        anchorEl={dialogAnchorEl}
        onClose={handleClose}
        onTransitionExited={() => {
          // Restore the current location if field was cleared
          if (!value && locationStore.currentLocation) {
            setValue(locationStore.currentLocation.location);
          }
        }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between">
          <Stack direction="row" alignItems="center" spacing={1}>
            <PageTitle />
            <Icon name={IconNames.MapPin} color={Theme.colorPalette.primary2} />
          </Stack>

          <IconButton
            icon={IconNames.Xmark}
            onClick={handleClose}
            aria-label="Close"
          />
        </Stack>

        <Box mt={1} mb={2.5}>
          <Typography>
            Enter a location to find resources in the area.
          </Typography>
        </Box>

        <LocationSearch value={value} onOptionSelect={setValue} />

        <Button
          disabled={!value}
          sx={{
            ...styles.button,
            ...styles.updateButton,
          }}
          variant="contained"
          onClick={handleClose}>
          Update
        </Button>
      </ResponsiveDialog>
    </>
  );
});

const styles = {
  button: {
    textTransform: 'none',
  },
  updateButton: {
    marginTop: 2,
    maxWidth: {
      sm: '100px',
    },
  },
};
