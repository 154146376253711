// @flow
import React, {useState, useEffect} from 'react';
import {observer} from 'mobx-react';
import {Drawer, Grid, Icon, Box, Button} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';

import {PrimaryNavigation} from './PrimaryNavigation';
import {Branding} from './Branding';

import {useStores} from 'hooks/useStores';

import {throttle} from 'utils/Utils';

export const SiteNavigation = observer((): Node => {
  const {appStore} = useStores();
  const {menuShowing, toggle} = appStore;
  const headerHeight = 80;
  const [sidebarTop, setSidebarTop] = useState(headerHeight);

  const toggleMenu = () => {
    toggle();
  };

  const handleScroll = throttle(() => {
    const header = document.querySelector('#site_header') || null;
    if (!header || header.classList.contains('visible')) {
      setSidebarTop(headerHeight);
    } else {
      setSidebarTop(0);
    }
  }, 100);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    const headerCheck = window.setInterval(handleScroll, 1000);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.clearInterval(headerCheck);
    };
  }, [handleScroll]);

  return (
    <>
      <Box
        data-testid="sidebar-scaling-box"
        sx={{
          width: menuShowing ? '308px' : '65px',
          transition: 'width 150ms',
        }}></Box>
      <Box
        data-testid="sidebar-root"
        sx={
          menuShowing
            ? {
                ...style.navigationRoot,
                ...style.navigationRootOpen,
                top: sidebarTop,
              }
            : {
                ...style.navigationRoot,
                top: sidebarTop,
              }
        }>
        <Drawer variant="permanent" anchor="left" sx={style.drawerRoot}>
          <Grid
            container
            direction="row"
            sx={
              menuShowing
                ? {...style.headerGrid, ...style.headerGridOpen}
                : style.headerGrid
            }>
            {menuShowing && <Branding />}
            <Button
              data-testid="navbar-toggle-button"
              size="medium"
              onClick={toggleMenu}
              disableRipple
              sx={{padding: 0, minWidth: '30px'}}>
              <Icon
                name={menuShowing ? 'SidebarCollapse' : 'SidebarExpand'}
                size={24}
                sx={{cursor: 'pointer'}}>
                {menuShowing ? 'SidebarCollapse' : 'SidebarExpand'}
              </Icon>
            </Button>
          </Grid>
          <PrimaryNavigation open={menuShowing} onNavigate={() => {}} />
        </Drawer>
      </Box>
    </>
  );
});

const style = {
  navigationRoot: {
    position: 'fixed',
    border: '1px #eee solid',
    borderRadius: '12px',
    width: '58px',
    padding: '12px 0',
    marginTop: '35px',
    marginBottom: '200px',
    overflow: 'auto',
    top: '0px',
    alignItems: 'start',
    overflowX: 'hidden',
    ' .MuiDrawer-paper': {
      borderRightWidth: 0,
      position: 'relative',
      overflowX: 'hidden',
    },
    backgroundColor: Theme.colorPalette.surface,
    transition: 'all 150ms',
  },
  navigationRootOpen: {
    borderRadius: '24px',
    width: '300px',
    padding: '18px 0 40px 0',
  },
  drawerRoot: {
    marginLeft: '0px',
    height: 'auto',
    overflowX: 'hidden',
  },
  headerGrid: {
    overflow: 'hidden',
    width: '56px',
    height: '60px',
    alignItems: 'start',
    justifyContent: 'center',
    padding: '16px 0px 16px 0px',
  },
  headerGridOpen: {
    justifyContent: 'space-between',
    padding: '5px 24px 0px 24px',
    width: '300px',
    height: '100px',
    flex: '1 1 100px',
  },
};
