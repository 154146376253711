// @flow
// React
import React, {useState, useEffect} from 'react';
import type {ComponentType, Node} from 'react';
import {useParams, Navigate} from 'react-router-dom';

// Third Party
import {observer} from 'mobx-react';
import * as Sentry from '@sentry/react';

// WS
import {Roles} from '@wellstone-solutions/common';
import type {RouteType} from 'types';
import {useStores} from 'hooks/useStores';
import {withRouter} from 'components/withRouter';
import {Routes as SharedRoutes} from 'components/Routes';
import PfLoader from 'components/shared/PfLoader';
import {SidePanel} from 'components/layout/SidePanel';
import {
  hasFlag,
  HIDE_MEMBERS_NAV,
  MAINTENANCE_MODE_BRIDGE,
  SENTRY_DEBUG,
  TMP_STORY_MANAGER,
  TMP_PEERS_DOCUMENTATION,
  TMP_SHARE_ANSWER,
  RESOURCES_V2,
  TMP_BRIDGE_WRAPPER,
} from 'constants/FeatureFlags';
import {NAVIGATION_NAMES} from 'constants/Navigation';
import {Breadcrumbs} from './authorized/Breadcrumbs';
import {AnnouncementsPage} from './authorized/Announcements';
import OverviewPage from './authorized/Overview';
import ConversationPage from './authorized/Conversations';
import {CalendarPage} from './authorized/Calendar';
import AdminDetailPage from './authorized/AdminDetail';
import GroupDetailPage from './authorized/GroupDetail';
import {
  MemberRoot,
  MemberLandingTabs,
  MemberList,
  MemberDetail,
  MemberDetailTabs,
} from 'modules/member';
import {GroupRoot} from 'modules/group';
import {StaffRoot} from 'modules/staff';
import {StoriesRoot} from 'modules/stories';
import {StoryNew} from 'modules/stories';
import {StoryLoader} from 'modules/stories';
import {NewCalendarEvent} from 'modules/calendar';
import {CalendarDetailPage} from 'modules/calendar';
import {DashboardRoot} from 'modules/dashboard';
import {DocumentationRoot} from 'modules/documentation';
import {MaintenanceMode} from 'components/MaintenanceMode/MaintenanceMode';
import {ResourcesRoot} from 'modules/resources';
import {
  ResourcesRoot as ResourcesRootV2,
  SavedResources,
  AllResources,
} from 'modules/resources/v2';

import {SiteNavigation} from 'modules/app/components/SiteNavigation';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';

export const AuthorizedFrame: ComponentType<> = observer((): Node => {
  const [loading, setLoading] = useState(true);
  const {
    meStore,
    organizationStore,
    groupStore,
    pubnubStore,
    channelStore,
  } = useStores();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const initFrame = async () => {
      if (!meStore.activeMembership) {
        await meStore.setActiveMembership(meStore.me);
      }

      await Promise.all([
        organizationStore.loadOrganization(
          meStore.activeMembership.organization.id,
        ),
        groupStore.loadGroups(meStore.activeMembership.organization.id),
      ]);

      if (hasFlag(SENTRY_DEBUG, meStore.features)) {
        Sentry.setUser({id: meStore.me.id});
      }

      if (!pubnubStore.connected) {
        pubnubStore.connect();
        channelStore.getUnreadChannels();
      }

      setLoading(false);
    };

    initFrame();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAuthorizedRoutes = (): Array<RouteType> => {
    const accessToMembers = !hasFlag(HIDE_MEMBERS_NAV, meStore.features);
    // Remove TMP_STORY_MANAGER feature flag, see PAT-3021
    const accessToStories = hasFlag(TMP_STORY_MANAGER, meStore.features);
    // Remove TMP_SHARE_ANSWER feature flag, see PAT-4212
    const accessToResources = hasFlag(TMP_SHARE_ANSWER, meStore.features);
    // Remove RESOURCES_V2 feature flag, see PAT-3489
    const accessToResourcesV2 = hasFlag(RESOURCES_V2, meStore.features);
    // Remove TMP_PEER_DOCUMENTATION feature flag, see PAT-3502
    const accessToDocumentation = hasFlag(
      TMP_PEERS_DOCUMENTATION,
      meStore.features,
    );

    let authorizedRoutes = [
      {
        path: 'announcements/:group_id',
        name: NAVIGATION_NAMES.announcements,
        Component: AnnouncementsPage,
        allowed: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.COUNSELOR, Roles.PEER],
      },
      {
        showInMenu: true,
        path: 'announcements',
        name: NAVIGATION_NAMES.announcements,
        Component: AnnouncementsPage,
        allowed: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.COUNSELOR, Roles.PEER],
      },
      {
        showInMenu: false,
        path: 'conversations/:channel',
        name: NAVIGATION_NAMES.conversation,
        Component: ConversationPage,
        allowed: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.COUNSELOR, Roles.PEER],
      },
      {
        showInMenu: true,
        path: 'conversations',
        name: NAVIGATION_NAMES.conversations,
        Component: ConversationPage,
        allowed: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.COUNSELOR, Roles.PEER],
      },
      {
        showInMenu: true,
        path: 'calendar',
        name: NAVIGATION_NAMES.calendar,
        Component: CalendarPage,
        allowed: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.COUNSELOR, Roles.PEER],
        isBottomRuled: true,
      },
      {
        path: 'calendar/calendar-event/new',
        name: NAVIGATION_NAMES.newEvent,
        Component: NewCalendarEvent,
        allowed: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.COUNSELOR, Roles.PEER],
      },
      {
        path: 'calendar/calendar-event/:id',
        name: NAVIGATION_NAMES.detailEvent,
        Component: CalendarDetailPage,
        allowed: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.COUNSELOR, Roles.PEER],
      },
      {
        path: 'dashboard/*',
        Component: () => {
          // Redirect nested dashboard routes
          const {'*': splat} = useParams();
          return <Navigate to={`/${splat}`} />;
        },
      },
      {
        showInMenu: true,
        path: 'dashboard',
        name: NAVIGATION_NAMES.dashboard,
        Component: DashboardRoot,
        allowed: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.COUNSELOR, Roles.PEER],
      },
    ];

    if (accessToMembers) {
      authorizedRoutes = [
        ...authorizedRoutes,
        {
          path: 'members/:member',
          name: NAVIGATION_NAMES.memberDetail,
          Component: withRouter(MemberDetail),
          allowed: [
            Roles.SUPER_ADMIN,
            Roles.ADMIN,
            Roles.COUNSELOR,
            Roles.PEER,
          ],
          children: [
            {
              path: 'activity',
              name: NAVIGATION_NAMES.memberDetailActivityTab,
              Component: MemberDetailTabs.ActivityTabContent,
              allowed: [
                Roles.SUPER_ADMIN,
                Roles.ADMIN,
                Roles.COUNSELOR,
                Roles.PEER,
              ],
            },
            {
              path: 'habit-schedule',
              name: NAVIGATION_NAMES.memberDetailHabitScheduleTab,
              Component: MemberDetailTabs.HabitScheduleTabContent,
              allowed: [
                Roles.SUPER_ADMIN,
                Roles.ADMIN,
                Roles.COUNSELOR,
                Roles.PEER,
              ],
            },
            {
              path: 'session-docs',
              name: NAVIGATION_NAMES.memberDetailSessionDocTab,
              Component: MemberDetailTabs.SessionDocTabContent,
              allowed: [
                Roles.SUPER_ADMIN,
                Roles.ADMIN,
                Roles.COUNSELOR,
                Roles.PEER,
              ],
              isDisabled: !accessToDocumentation,
            },
            {
              index: true,
              name: NAVIGATION_NAMES.memberDetailGeneralTab,
              Component: MemberDetailTabs.GeneralTabContent,
              allowed: [
                Roles.SUPER_ADMIN,
                Roles.ADMIN,
                Roles.COUNSELOR,
                Roles.PEER,
              ],
            },
          ],
        },
        {
          showInMenu: true,
          path: 'members',
          name: NAVIGATION_NAMES.members,
          Component: MemberRoot,
          allowed: [
            Roles.SUPER_ADMIN,
            Roles.ADMIN,
            Roles.COUNSELOR,
            Roles.PEER,
          ],
          children: [
            {
              path: 'documentation',
              name: NAVIGATION_NAMES.documentation,
              Component: MemberLandingTabs.SessionDocTabContent,
              allowed: [
                Roles.SUPER_ADMIN,
                Roles.ADMIN,
                Roles.COUNSELOR,
                Roles.PEER,
              ],
              isDisabled: !accessToDocumentation,
            },
            {
              index: true,
              name: NAVIGATION_NAMES.members,
              Component: MemberList,
              allowed: [
                Roles.SUPER_ADMIN,
                Roles.ADMIN,
                Roles.COUNSELOR,
                Roles.PEER,
              ],
              isDisabled: !accessToDocumentation,
            },
          ],
        },
      ];

      // Adds routes for documentation
      if (accessToDocumentation) {
        authorizedRoutes = [
          ...authorizedRoutes,
          {
            path: 'members/:member/documentation',
            name: NAVIGATION_NAMES.documentation,
            Component: withRouter(DocumentationRoot),
            allowed: [
              Roles.SUPER_ADMIN,
              Roles.ADMIN,
              Roles.COUNSELOR,
              Roles.PEER,
            ],
          },
          {
            path: 'members/:member/documentation/:id',
            name: NAVIGATION_NAMES.documentation,
            Component: withRouter(DocumentationRoot),
            allowed: [
              Roles.SUPER_ADMIN,
              Roles.ADMIN,
              Roles.COUNSELOR,
              Roles.PEER,
            ],
          },
        ];
      }
    }

    authorizedRoutes = [
      ...authorizedRoutes,
      {
        path: 'groups/:group',
        name: NAVIGATION_NAMES.groupDetail,
        Component: GroupDetailPage,
        allowed: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.COUNSELOR, Roles.PEER],
      },
      {
        showInMenu: true,
        path: 'groups',
        name: NAVIGATION_NAMES.groups,
        Component: GroupRoot,
        allowed: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.COUNSELOR, Roles.PEER],
      },
      {
        path: 'staff/:admin',
        name: NAVIGATION_NAMES.staffDetail,
        Component: withRouter(AdminDetailPage),
        allowed: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.COUNSELOR, Roles.PEER],
      },
      {
        showInMenu: true,
        path: 'staff',
        name: NAVIGATION_NAMES.staff,
        Component: StaffRoot,
        allowed: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.COUNSELOR, Roles.PEER],
        isBottomRuled: accessToStories && !meStore.isSuperAdmin,
      },
      {
        showInMenu: true,
        path: 'activity',
        name: NAVIGATION_NAMES.activity,
        Component: OverviewPage,
        allowed: [Roles.SUPER_ADMIN],
        isBottomRuled: accessToStories,
      },
    ];

    if (accessToResources || accessToResourcesV2) {
      authorizedRoutes = [
        ...authorizedRoutes,
        {
          showInMenu: true,
          path: 'resources',
          name: NAVIGATION_NAMES.resources,
          Component: accessToResourcesV2 ? ResourcesRootV2 : ResourcesRoot,
          allowed: [
            Roles.SUPER_ADMIN,
            Roles.ADMIN,
            Roles.COUNSELOR,
            Roles.PEER,
          ],
          children: accessToResourcesV2
            ? [
                {
                  index: true,
                  name: NAVIGATION_NAMES.resourcesAll,
                  Component: AllResources,
                  allowed: [
                    Roles.SUPER_ADMIN,
                    Roles.ADMIN,
                    Roles.COUNSELOR,
                    Roles.PEER,
                  ],
                },
                {
                  path: 'saved',
                  name: NAVIGATION_NAMES.savedResources,
                  Component: SavedResources,
                  allowed: [
                    Roles.SUPER_ADMIN,
                    Roles.ADMIN,
                    Roles.COUNSELOR,
                    Roles.PEER,
                  ],
                },
              ]
            : [],
        },
      ];
    }

    if (accessToStories) {
      authorizedRoutes = [
        ...authorizedRoutes,
        {
          showInMenu: true,
          path: 'stories',
          name: NAVIGATION_NAMES.stories,
          Component: StoriesRoot,
          allowed: [
            Roles.SUPER_ADMIN,
            Roles.ADMIN,
            Roles.COUNSELOR,
            Roles.PEER,
          ],
        },
        {
          showInMenu: false,
          path: 'stories/new',
          name: NAVIGATION_NAMES.storyNew,
          Component: StoryNew,
          allowed: [
            Roles.SUPER_ADMIN,
            Roles.ADMIN,
            Roles.COUNSELOR,
            Roles.PEER,
          ],
        },
        {
          showInMenu: false,
          path: 'stories/:id',
          name: NAVIGATION_NAMES.storyEdit,
          Component: StoryLoader,
          allowed: [
            Roles.SUPER_ADMIN,
            Roles.ADMIN,
            Roles.COUNSELOR,
            Roles.PEER,
          ],
        },
      ];
    }

    const defaultPage = '/dashboard';
    authorizedRoutes.push({
      Component: (): Node => <Navigate to={defaultPage} />,
      isProtected: true,
      path: '*',
      allowed: [],
    });

    return authorizedRoutes;
  };

  if (loading) {
    return <PfLoader />;
  }

  if (hasFlag(MAINTENANCE_MODE_BRIDGE, meStore.features)) {
    return <MaintenanceMode />;
  }

  const authorizedRoutes = getAuthorizedRoutes();

  const pageStyle = hasFlag(TMP_BRIDGE_WRAPPER, meStore.features)
    ? {...styles.dashboard, paddingTop: 80}
    : styles.dashboard;

  return (
    <div className="contained" style={pageStyle}>
      {hasFlag(TMP_BRIDGE_WRAPPER, meStore.features) ? (
        !isMobile && <SiteNavigation />
      ) : (
        <SidePanel routes={authorizedRoutes} />
      )}
      <div style={styles.main}>
        <Breadcrumbs />
        <SharedRoutes routes={authorizedRoutes} />
      </div>
    </div>
  );
});

const styles = {
  dashboard: {
    flexDirection: 'row',
    display: 'flex',
  },
  main: {
    flex: 1,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 40,
    minWidth: 0,
  },
};
