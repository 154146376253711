// @flow
import {Checkin} from '@wellstone-solutions/common/models/rest';
import {useEffect, useState} from 'react';
import type {ApiResponseType} from '@wellstone-solutions/common';
import type {UITagType} from '@wellstone-solutions/common/models/rest';

const getUITags = (): Promise<ApiResponseType<UITagType>> =>
  Checkin.getTags().then((response) => ({
    ...response,
    data: response.isSuccess ? response.data.map(Checkin.toUITag) : [],
  }));

export const useTags = (): Array<UITagType> => {
  const [tags, setTags] = useState<Array<UITagType>>([]);

  useEffect(() => {
    if (tags.length === 0) {
      getUITags().then((response) => setTags(response.data));
    }
  }, [tags]);

  return tags;
};
